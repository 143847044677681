'use client';
import { Provider } from '@rollbar/react';
import { ReactNode } from 'react';

import { baseConfig } from '@/lib/rollbarConfig';

const rollbarConfig = {
  ...baseConfig,
  accessToken: process.env.NEXT_PUBLIC_ROLLBAR_ACCESS_TOKEN,
};

export const RollbarProvider = ({ children }: { children: ReactNode }) => {
  return <Provider config={rollbarConfig}>{children}</Provider>;
};
