'use client';

import { FC, PropsWithChildren } from 'react';
import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react';

import { frontendConfig } from '@/lib/auth/config/frontend';

if (typeof window !== 'undefined') {
  // we only want to call this init function on the frontend, so we check typeof window !== 'undefined'
  SuperTokens.init(frontendConfig());
}

export const SuperTokensInit: FC<PropsWithChildren<{}>> = ({ children }) => {
  return <SuperTokensWrapper>{children}</SuperTokensWrapper>;
};
