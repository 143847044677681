export function isAuthError(error: unknown) {
  return error instanceof AuthActionError || error instanceof AuthActionInvalid;
}

export class AuthActionError extends Error {
  /**
   * An error from calling the authentication api that
   * means something unexpected went wrong. This will result in a report
   * to rollbar
   * @param message: User friendly message to present to the client
   */
  constructor(message: string) {
    super(message);
  }
}

export class AuthActionInvalid extends Error {
  /**
   * An error from calling the authentication api that
   * means a user messed something up. E.g. signup when they already
   * have a user.
   * This will not report to rollbar.
   * @param message: User friendly message to present to the client
   */
  constructor(message: string) {
    super(message);
  }
}

export class AuthActionProvisioningFailed extends Error {
  /**
   * An error that means provisioning the user failed, we need to log in after
   * the signup to finish provisioning
   *
   * @param message: User friendly message to present to the client
   */
  constructor(message: string) {
    super(message);
  }
}
