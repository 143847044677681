'use client';

import { useRollbar } from '@rollbar/react';
import { ReactElement, useState } from 'react';
import {
  QueryClient,
  QueryClientProvider,
  QueryCache,
  MutationCache,
} from 'react-query';

import { AuthActionInvalid } from '@/api/auth/error';

type Props = { children: ReactElement };

export function ReactQueryProvider({ children }: Props) {
  const rollbar = useRollbar();
  // When a query or mutation fails, log the error to Rollbar
  const onError = (error: any) => {
    let response;
    if (error instanceof AuthActionInvalid) {
      // AuthActionInvalid means it's something in normal user flow,
      // a validation error. Don't report in this case
      return;
    }
    if (error instanceof Error) {
      // If the error's message includes the serialized graphql response,
      // remove it from the message and pass it as a custom field instead
      const respIndex = error.message.indexOf(': {"response"');
      if (respIndex > 0) {
        response = error.message.slice(respIndex + 2); // remove ': '
        error.message = error.message.slice(0, respIndex);
      }
    }
    rollbar.error(error, response ? { response } : {});
  };

  const queryCache = new QueryCache({ onError });
  const mutationCache = new MutationCache({ onError });
  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache,
        mutationCache,
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            // Avoid memory pressure on server, on client use normal default
            cacheTime: typeof window === 'undefined' ? Infinity : 5 * 60 * 1000,
          },
        },
      })
  );
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
}
