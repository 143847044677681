'use client';

import { FC } from 'react';

export const PortalRoots: FC = () => (
  <>
    <div id="headlessui-portal-root">
      {/* Important that a div is inside the portal root */}
      <div />
    </div>
    <div id="toast-portal-root" />
  </>
);
